<template>
  <div id="main-title-outter" class="border-bottom mb-4">
    <div id="main-title-inner" class="container">
      <div class="main-title-breadcrumb">
        <slot name="breadcrumb"></slot>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="main-title me-auto">
          <h1>{{title}}</h1>
        </div>
        <div class="main-title-actions">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroTitle",
  computed: {
    hasActions() {
      return (typeof this.$slots.actions !== 'undefined');
    }
  },
  props: {
    title: {
      required: true,
      type: String,
    }
  }
}
</script>