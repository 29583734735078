<template>
  <div class="dropdown">A
    <a href="#" class="d-flex align-items-center link-dark text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
      <img src="https://i.pravatar.cc/32" alt="" class="rounded-circle me-2" width="32" height="32">
      <strong>mdo</strong>
    </a>
    <ul v-if="isAuthed" class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
      <li><router-link class="dropdown-item" to="/profile">Perfil</router-link></li>
      <li><hr class="dropdown-divider"></li>
      <li><a class="dropdown-item" href="/logout" @click.prevent="logout">Cerrar sesion</a></li>
    </ul>
  </div>
</template>

<script src="../script.js"></script>