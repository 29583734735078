import Toast, { POSITION } from "vue-toastification";
import store from './store'

export default {
  install(app, options) {

    if (typeof options === 'undefined') {
      console.warn("options is required");
    }

    app.use(Toast, {
      position: POSITION.BOTTOM_LEFT
    });
    options.store.registerModule("notifications", store);
  }
};
