<script>

export default {
  namespaced: true,
  state: {
    all: [],
    byTopic: {}
  },
  mutations: {
    setAll(state, data) {
      state.all = data;
    },
    setByTopic(state, data) {
      state.byTopic[data.topic_id] = data.posts;
    },
    addReply(state, data) {
      state.byTopic[data.topic_id].push(data.post);
    }
  },
  actions: {
    /*loadAll({ commit }, data) {
      //console.log(data);
      http.get(`forums/${data.forum_id}/topics`).then(function(response) {
        commit("setAll", response.data);
      });
    },*/
    byTopic({ commit }, data) {
      //console.log(data);
      this.$http.get(`topics/${data.topic_id}/posts`).then(function(response) {
        data.posts = response.data;
        commit("setByTopic", data);
        commit('setAll', data.posts);
      });
    },
    edit({ commit }, data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`posts/${data.post_id}/edit`).then(function(response) {
          resolve(response.data);
        });
      });
    },
    save({ commit }, data) {
      //console.log(data);
      var url = `topics/${data.topic_id}/posts`;
      this.$http.post(url, data.post).then(function(response) {
        let d = Object.assign({}, data);
        d.post = response.data;
        commit('posts/addReply', d, { root: true });
      });
    },
    update({ commit }, data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        self.$http.put(`posts/${data.post_id}`, data.post).then(function(response) {
          resolve(response.data);
        });
      });
    }
  }
};
</script>
