<template>
  <ul class="nav nav-pills flex-column mb-auto">
    <li class="nav-item" v-for="item in menu" :key="item.name">
      <router-link class="nav-link" v-if="!item.menu" :to="{ name: item.route.name }">
        {{ item.title }}
      </router-link>
    </li>
  </ul>
</template>
<!--template>
  <nav class="nav">
    <ul>
      <li v-for="item in menu" :key="item.name">
        <router-link  v-if="!item.menu" :to="{ name: item.route.name }">
          {{ item.title }}
        </router-link>
        <menu-dropdown v-if="item.menu" v-bind:item="item"></menu-dropdown>
      </li>
    </ul>
  </nav>
</template-->

<script src="./script.js"></script>
