<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link v-if="forum" :to="{'name':'home'}">Inicio</router-link>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        <router-link v-if="forum" :to="{'name':'forum', params: {id: forum.id}}">{{forum.name}}</router-link>
      </li>
    </ol>
  </nav>  
</template>

<script>
export default {
  name: 'BreadCrumb',
  props: {
    forum: {
      type: Object
    }
  }
}
</script>