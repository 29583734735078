export default {
  name: "LoginModal",
  id: "login-modal",
  data() {
    return {
      el: "login",
      errors: [],
      error: false,
      email: "",
      password: ""
    };
  },
  methods: {
    cancel() {
      this.reject("Cancelled");
    },
    login() {
      this.error = false;
      this.errors = [];
      var self = this;
      self.$store
        .dispatch("auth/login", {
          email: this.email,
          password: this.password
        })
        .then(function(jwt) {
          self.resolve(jwt);
        }).catch(function(err) {
          //console.log(err);
          self.error = err.response.data;
          console.log(self.error);
          var self2 = self;
          if (err.response.status == 401) {
            self2.$auth.verifyModal({
              props: {
                email: self.email,
                password: self.password,
                code: ""
              }
            }).then(function(jwt) {
              self2.resolve(jwt);
              self.resolve(jwt);
            });
          }
        });;
    },
    reset() {
      if (!this.email) {
        alert("No hay mail");
        return false;
      }

      var obj = {
        props: {
          email: this.email
        }
      };
      /*this.$auth.newPassModal(obj).then(function(res){}).catch(function(errors) {
        console.log(errors);
      });
      return;*/
      

      var self = this;
      self.$store
        .dispatch("auth/reset", {
          email: self.email
        })
        .then(function(msg) {
          var obj = {
            props: {
              email: self.email
            }
          };
          self.$auth.newPassModal(obj).then(function(res) {
            self.resolve(res);
          }).catch(function(err) {
            console.log(err);
          });
        }).catch(function(errors) {
          self.errors = errors;
        });;
    }
  }
};
