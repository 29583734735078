<template>
  <design-two title="Foros">
    <div class="forum-list">
      <div
        class="row bg-light py-2 my-2"
        v-for="forum in forums"
        v-bind:key="forum.id"
      >
        <div class="col-8">
          <router-link :to="{ name: 'forum', params: { id: forum.id } }">{{
            forum.name
          }}</router-link>
        </div>
        <div class="col-4">-</div>
      </div>
    </div>
    <section>
      <h2>Ultimos temas</h2>
      <topic-list v-if="newestTopics" :showForum="true" :topics="newestTopics"></topic-list>
    </section>
    <section>
      <h2>Actividad reciente</h2>
      <topic-list v-if="activeTopics" :showForum="true" :topics="activeTopics"></topic-list>
    </section>
  </design-two>
</template>

<script>
import { DesignTwo } from "@linkedcode/design";
import TopicList from '../components/TopicList.vue';

export default {
  name: "EnforosHome",
  components: {
    DesignTwo,
    TopicList,
  },
  computed: {
    forums() {
      return this.$store.state.forums.all;
    },
    newestTopics() {
      return this.$store.state.topics.newest;
    },
    activeTopics() {
      return this.$store.state.topics.activity;
    }
  },
  mounted() {
    this.$store.dispatch("forums/loadAll");
    this.$store.dispatch("topics/newest");
    this.$store.dispatch("topics/activity");
  },
};
</script>