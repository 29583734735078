//import Modal from "./components/Modal.vue";
//import store from "./store";
import BootstrapModal from "./components/BootstrapModal.vue";

export default {
  installed: false,
  install(app, options) {
    if (this.installed) return;

    if (typeof app.config.globalProperties.$emitter === 'undefined') {
        console.warn('Se necesita app.config.globalProperties.$emitter');
    }

    app.component("modal-plugin", BootstrapModal);

    app.config.globalProperties.$openModal = function(opts) {
      console.log('app.config.globalProperties.$openModal');
      
      let a = app.component(opts.component.id);
      if (typeof a === 'undefined') {
        app.component(opts.component.id, opts.component);
      }

      return new Promise(function(resolve, reject) {
        opts.resolve = resolve;
        opts.reject = reject;

        app.config.globalProperties.$emitter.emit('OPEN_MODAL', opts);
      });
    };

    app.config.globalProperties.$modal = function(component) {
      let a = app.component(component.id);
      if (typeof a === 'undefined') {
        app.component(component.id, component);
      }

      return new Promise(function(resolve, reject) {
        component.resolve = resolve;
        component.reject = reject;
        //options.emitter.emit('OPEN_MODAL', component);
        app.config.globalProperties.$emitter.emit('OPEN_MODAL', component);
      });
    };

    this.installed = true;
  }
};
