<script>

export default {
  namespaced: true,
  state: {
    all: {},
    active: {},
  },
  mutations: {
    setAll(state, data) {
      data.forEach(function(f) {
        state.all[f.id] = f;
      });
    },
    load(state, data) {
      state.all[data.forum_id] = data.data;
      state.active = data.data;
    },
    activate(state, forumId) {
      //console.log("activating");
      state.active = state.all[forumId];
    }
  },
  actions: {
    loadAll({ commit }) {
      //console.log(this);
      this.$http.get("forums").then(function(response) {
        commit("setAll", response.data);
      });
    },
    load(context, data) {
      //console.log(context.state.all, data);
      if (data.forum_id in context.state.all) {
        context.commit("activate", data.forum_id);
      } else {
        var self = this;
        return new Promise(function(resolve, reject) {
          self.$http.get(`forums/${data.forum_id}`).then(function(response) {
            //console.log("loading");
            context.commit("load", {data: response.data, forum_id: data.forum_id});
            resolve(response.data);
          });
        })
      }
    }
  }
};
</script>
