
export default {
  computed: {
    userId: function() {
      return this.$store.getters["auth/userId"];
    },
    isAuthed: function() {
      return this.$auth.isAuthed();
    },
    username: function() {
      var name = this.$store.getters['profile/username'];
      if (name === false) {
        if (this.$store.getters['auth/isAuthed']) {
          this.$store.dispatch('profile/me');
        }
        return 'Anonimo';
      } else {
        return name;
      }
    }
  },
  methods: {
    logout: function() {
      this.$store.dispatch("auth/logout");
    },
    login: function() {
      //this.$router.push('/login')
      //console.log('login');
      this.$auth
        .loginModal()
        .then(function() {
          console.log("UserApplet:login:ok");
        })
        .catch(function(err) {
          console.log("UserApplet:login:error", err);
        });
    },
    register: function() {
      var self = this;
      this.$auth
        .registerModal()
        .then(function(data) {
          console.log("UserApplet:register:ok", data);
          self.$auth.verifyModal({ props: data }).then(function(data){
            console.log(data);
          }).catch(function(err) {
            console.log(err);
          });
        })
        .catch(function(err) {
          console.log("UserApplet:register:error", err);
        });
    }
  }
};