<template>
  <div class="main-wrapper mt-2">
    <main>
      <main-header-wrapper>
        <slot name="mainHeader"></slot>
      </main-header-wrapper>
      <main-content-wrapper>
        <slot name="default"></slot>
      </main-content-wrapper>
    </main>
    <aside>
      <slot name="aside"></slot>
    </aside>
  </div>
</template>

<script>
import MainContentWrapper from './MainContentWrapper.vue'
import MainHeaderWrapper from './MainHeaderWrapper.vue'

export default {
  components: { MainHeaderWrapper, MainContentWrapper },
  name: "MainWrapper",
  props: {
    title: {
      required: true,
      type: String,
    }
  }
}
</script>