<template>
  <div class="main-header">
    <div class="main-header-title">
      <slot name="default"></slot>
    </div>
    <div v-if="hasActions" class="main-header-actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainHeaderWrapper",
  computed: {
    hasActions() {
      return (typeof this.$slots.actions !== 'undefined');
    }
  }
}
</script>