<template>
  <design-two title="Editar respuesta">
    <form>
      <fieldset>
        <label for="inputPassword6" class="col-form-label">Mensaje</label>
        <textarea class="form-control" v-model="post.markdown"></textarea>
        <div class="row justify-content-between mt-4" >
          <div class="col-1">
            <button type="button" class="btn btn-secondary" v-on:click="cancel">Cancelar</button>
          </div>
          <div class="col-1">
            <button type="button" class="btn btn-primary" v-on:click="submit()">Guardar</button>
          </div>
        </div>
      </fieldset>
    </form>
  </design-two>
</template>

<script>
import { DesignTwo } from "@linkedcode/design";

export default {
  name: "PostEdit",
  computed: {
  },
  components: {
    DesignTwo
  },
  data() {
    return {
      post: {
        markdown: '',
      },
      postId: 0,
      topicId: 0
    }
  },
  methods: {
    submit() {
      if (this.postId > 0) {
        this.update();
      } else {
        this.save();
      }
    },
    save() {
      let self = this;
      //console.log(this.topic);
      this.topic.markdown = this.topic.content;
      this.$store.dispatch("topics/create", {
        topic: this.topic,
        forumId: this.forumId
      }).then(function() {
        self.$router.push({ name: 'forum', params: {
            id: self.$route.params.id
        }});
      });
    },
    update() {
      let self = this;
      let data = {
        post: this.post,
        post_id: this.postId
      };
      this.$store.dispatch("posts/update", data).then(function() {
        self.$router.push({ name: 'topic', params: {
            id: self.topicId
        }});
      });
    },
    cancel() {
      this.$router.go(-1);
    }
  },
  created() {
    var vm = this;
    if (this.$route.name == 'post-edit') {
      this.postId = this.$route.params.id;
      this.$store.dispatch("posts/edit", { post_id: this.postId })
        .then(function(post){
          vm.post.markdown = post.markdown;
          vm.topicId = post.topic_id;
        }
      )
    }
  }
}
</script>
