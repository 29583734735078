import { createApp } from 'vue'
import App from './App.vue'

import store from './store'
import router from './router'
import mitt from 'mitt'
//import LinkedcodeApp from '@linkedcode/app'
import EnforosApp from '@linkedcode/enforos'
import AuthPlugin from '@linkedcode/auth'
import ModalPlugin from '@linkedcode/modal'
import NavbarMenu from '@linkedcode/navbar-menu'
import AxiosPlugin from '@linkedcode/axios-plugin'
import AppStorePlugin from '@linkedcode/app-store-plugin'
import Notifications from '@linkedcode/notifications'

import config from "./config.js";
//console.log(config);
const emitter = mitt();

/*EnforosApp.routes.forEach(function(route) {
    router.addRoute(route);
});

EnforosApp.vuex.forEach(function(s) {
    store.registerModule(s.name, s.module);
});*/

//router.addRoute();
const app = createApp(App);
app.use(store);
app.config.globalProperties.$emitter = emitter;
app.use(AuthPlugin, {
    store, router
});
app.use(AxiosPlugin, {
  store: store,
  api: {
    url: config.api.url
  }
});
app.use(Notifications, { store });
app.use(NavbarMenu);
app.use(AppStorePlugin, {
  store, router
});
app.use(ModalPlugin, {
    store, emitter
});
app.config.globalProperties.$appStore.add(EnforosApp);
app.use(router);
app.config.globalProperties.$appStore.activate(EnforosApp);

app.mount('#app');
