import { base } from "@linkedcode/axios-plugin";

export default {
  login(email, password) {
    const data = {
      email: email,
      password: password
    };

    var self = this;

    return new Promise(function(resolve, reject) {
      const URL = "/login";
      console.log("Login", base);

      base.post(URL, data)
        .then(function(response) {
          self
            .handleAuthentication(response)
            .then(function(token) {
              resolve(token);
            })
            .catch(function(err) {
              reject(err);
            });
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  reset(email) {
    const data = {
      email: email
    };

    return new Promise(function(resolve, reject) {
      //const URL = "/register";

      base.post('/auth/reset', data)
        .then(function(response) {
          //console.log(response);
          resolve(response);
        })
        .catch(function(err) {
          if (err.response.data.errors) {
            reject(err.response.data.errors);
          } else if (err.response.data.error_description) {
            reject([err.response.data.error_description]);
          } else {
            reject(err);
          }
        });
    });
  },

  resetsave(email, password, code) {
    const data = {
      email: email,
      password: password,
      code: code
    };

    return new Promise(function(resolve, reject) {
      //const URL = "/register";

      base.post('/auth/reset/save', data)
        .then(function(response) {
          //console.log(response);
          resolve(response);
        })
        .catch(function(err) {
          if (err.response.data.errors) {
            reject(err.response.data.errors);
          } else if (err.response.data.error_description) {
            reject([err.response.data.error_description]);
          } else {
            reject(err);
          }
        });
    });
  },

  register(email, password) {
    const data = {
      email: email,
      password: password
    };

    var self = this;

    return new Promise(function(resolve, reject) {
      const URL = "/register";

      base.post(URL, data)
        .then(function(response) {
          resolve(response);
          /*self
            .handleAuthentication(response)
            .then(function(token) {
              resolve(token);
            })
            .catch(function(err) {
              console.log(err);
              reject(err.response.data.errors);
            });
            */
        })
        .catch(function(err) {
          if (err.response.data.errors) {
            reject(err.response.data.errors);
          } else if (err.response.data.error_description) {
            reject([err.response.data.error_description]);
          } else {
            reject(err);
          }
        });
    });
  },

  verifynewcode(email, password) {
    const data = {
      email: email,
      password: password
    };

    return new Promise(function(resolve, reject) {
      const URL = "/auth/verify/code";

      base.post(URL, data)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  verify(email, password, code) {
    const data = {
      email: email,
      password: password,
      code: code
    };

    var self = this;

    return new Promise(function(resolve, reject) {
      const URL = "/verify";

      base.post(URL, data)
        .then(function(response) {
          //resolve(response);
          self
            .handleAuthentication(response)
            .then(function(token) {
              resolve(token);
            })
            .catch(function(err) {
              reject(err);
            });
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  handleAuthentication(response) {
    return new Promise((resolve, reject) => {
      if (response.data && response.data.access_token) {
        resolve(response.data.access_token);
      } else {
        reject(response.data.errors);
      }
    });
  }
};
