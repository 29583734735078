import { createRouter, createWebHistory } from 'vue-router'

import Home from '@linkedcode/enforos/src/views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }
]

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
