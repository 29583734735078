<template>
  <div class="posts">
    <h2>Respuestas</h2>
    <div class="post-list">
      <post v-for="reply in replies" :key="reply.id" :post="reply"></post>
    </div>
  </div>
</template>

<script>
import Post from "./Post.vue";

export default {
  props: {
    replies: {
      type: Array
    }
  },
  computed: {
  },
  components: {
    Post
  },
  data() {
    return {
    }
  },
  methods: {
  },
  created() {
  },
  mounted() {
  }
}
</script>
