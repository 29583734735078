<template>
  <header class="border-bottom mb-2">
    <navbar-header brand="EnForos"></navbar-header>
  </header>
  <router-view></router-view>
  <div class="border-top mt-4">
    <div class="container-fluid">
      <div class="container py-4">
        <footer>Enforos</footer>
      </div>
    </div>
  </div>
  <toasts></toasts>
  <modal-plugin></modal-plugin>
</template>

<script>
import Toasts from "@/components/ToastContainer.vue"

export default {
  name: 'App',
  components: {
    Toasts
  }
}
</script>
<style>
body {
  font-family: "Ubuntu Mono";
}
#nprogress .bar {
  height: 4px;
  background: #0d6efd;
}
#nprogress .peg {
  box-shadow: 0 0 20px #0a58ca, 0 0 10px #0a58ca;
}
</style>