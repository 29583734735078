import ForumStore from "./ForumStore.vue";
import TopicsStore from "./TopicsStore.vue";
import PostsStore from "./PostsStore.vue";

import Enforos from "./views/Home.vue";
import Forum from "./views/Forum.vue";
import Topic from "./views/Topic.vue";
import NewTopic from "./views/NewTopic.vue";
import PostEdit from "./views/PostEdit.vue";

export default {
  installed: false,
  install(app) {
    if (this.installed) return;
    this.installed = true;
  },
  name: "Enforos",
  route: "listings",
  vuex: [
    {
      name: "forums",
      module: ForumStore
    },
    {
      name: "topics",
      module: TopicsStore
    },
    {
      name: "posts",
      module: PostsStore
    }
  ],
  menu: [
    {
      title: "Inicio",
      id: "home",
      icon: false,
      route: {
        name: "home"
      }
    },
    {
      title: "Foros",
      id: "forums",
      icon: false,
      route: {
        name: "forums"
      }
    }
  ],
  routes: [
    {
      path: "/",
      name: "home",
      component: Enforos,
      meta: {
        title: "Inicio",
        requiresAuth: false
      }
    }, {
      path: "/forums/:id",
      name: "forum",
      component: Forum,
      meta: {
        title: "Inicio",
        requiresAuth: false
      }
    }, {
      path: "/forums/:id/topic",
      name: "newtopic",
      component: NewTopic,
      meta: {
        title: "Inicio",
        requiresAuth: true
      }
    }, {
      path: "/topics/:id",
      name: "topic",
      component: Topic,
      meta: {
        title: "Tema",
        requiresAuth: false
      }
    }, {
      path: "/topics/:id/edit",
      name: "topic-edit",
      component: NewTopic,
      meta: {
        title: "Tema",
        requiresAuth: true
      }
    }, {
      path: "/posts/:id/edit",
      name: "post-edit",
      component: PostEdit,
      meta: {
        requiresAuth: true
      }
    }, {
      path: "/forums",
      name: "forums",
      component: Enforos,
      meta: {
        title: "Foros",
        requiresAuth: false
      }
    }
  ]
}
