<template>

  <!--div class="collapse navbar-collapse">
    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      <li class="nav-item dropdown">
        <a href="#" class="d-block nav-link text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
          
        </a>
      </li>
    </ul>
  </div-->
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <span>{{username}}</span>
          </a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownUser2">
            <li v-if="isAuthed" ><router-link class="dropdown-item" to="/profile">Perfil</router-link></li>
            <li v-if="isAuthed" ><hr class="dropdown-divider"></li>
            <li v-if="isAuthed" ><a class="dropdown-item" href="#" @click="logout">Cerrar sesion</a></li>
            <li v-if="!isAuthed" ><a class="dropdown-item" href="/login" @click.prevent="login">Ingresar</a></li>
            <li v-if="!isAuthed" ><a class="dropdown-item" href="/register" @click.prevent="register">Registrarse</a></li>
          </ul>
        </li>
      </ul>
    </div>
</template>

<script src="../script.js"></script>