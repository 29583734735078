<script>

export default {
  namespaced: true,
  state: {
    all: [],
    active: {},
    topics: {},
    newest: [],
    activity: [],
    forum: {}
  },
  mutations: {
    setAll(state, data) {
      state.all = data;
    },
    setTopic(state, data) {
      state.active = data.topic;
      state.topics[data.topic_id] = data.topic;
    },
    setByForum(state, data) {
      //console.log(data);
      state.forum[data.forum_id] = data.topics;
    },
    setNew(state, topics) {
      state.newest = topics;
    },
    setActivity(state, topics) {
      state.activity = topics;
    },
    addReply(state, data) {
      //console.log(state);
      //console.log(data);
    }
  },
  actions: {
    loadByForum({ commit }, data) {
      this.$http.get(`forums/${data.forum_id}/topics`).then(function(response) {
        data.topics = response.data;
        commit("setByForum", data);
      });
    },
    load({ commit }, data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`topics/${data.topic_id}`).then(function(response) {
          data.topic = response.data;
          commit("setTopic", data);
          resolve(response.data);
        });
      });
    },
    newest({ commit }) {
      var self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`topics?order=new&limit=5`).then(function(response) {
          commit("setNew", response.data);
          resolve(response.data);
        });
      });
    },
    activity({ commit }) {
      var self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`topics?order=activity&limit=5`).then(function(response) {
          commit("setActivity", response.data);
          resolve(response.data);
        });
      });
    },
    edit({ commit }, data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        self.$http.get(`topics/${data.topic_id}/edit`).then(function(response) {
          resolve(response.data);
        }).catch(function(err) {
          //console.log(err.response.data);
        });
      });
    },
    update({ commit }, data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        self.$http.put(`topics/${data.topic_id}`, data.topic).then(function(response) {
          resolve(response.data);
        }).catch(function(err) {
          //console.log(err.response.data);
        });
      });
    },
    create({ commit }, data) {
      var self = this;
      return new Promise(function(resolve, reject) {
        self.$http.post(`forums/${data.forumId}/topics`, data.topic).then(function(response) {
          //commit('posts/addReply', d, { root: true });
          resolve();
        }).catch(function(err) {
          reject(err);
        });
        
      });
    }
    /*,
    
    loadByTopic({ commit }, data) {
      console.log(data);
      http.get(`topics/${data.topic_id}/posts`).then(function(response) {
        commit("setByTopic", response.data);
      });
    }*/
  }
};
</script>
