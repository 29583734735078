<template>
  <div id="login-modal" class="modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Iniciar Sesión</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="alert alert-warning" role="alert" v-if="error">
              {{error.error_description}}
            </div>
            <fieldset>
              <label class="my-2">Correo electrónico</label>
              <input
                type="text"
                v-model="email"
                class="form-control"
                autocomplete="username"
                placeholder="Usuario o correo electrónico"
              />

              <label class="my-2">Contraseña</label>
              <input
                type="password"
                v-model="password"
                autocomplete="current-password"
                class="form-control"
                placeholder="Contraseña"
              />
            </fieldset>
          </form>
        </div>
        <div class="modal-footer">
          <div class="hstack gap-2">
            <button type="button" v-on:click="reset()" class="btn btn-secondary">
              Resetear contraseña
            </button>
          
            <!-- data-bs-dismiss="modal" -->
            <button
              type="button"
              class="btn btn-secondary ms-auto"
              v-on:click="cancel()"
            >
              Cancelar
            </button>
            <button type="button" v-on:click="login()" class="btn btn-primary">Iniciar Sesion</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!--
  <div class="lkmodal slideout">
    
  </div>

-->
<script src="./login.js"></script>
