<template>
  <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <component v-for="toast in toasts" v-bind:is="Toast" v-bind="toast.props" v-bind:key="toast.id">
    </component>
  </div>
</template>

<script>
export default {
  name: "Toasts",
  data() {
    return {
      toasts: []
    }
  },
  methods: {
    alert(msg) {
      var toast = {
        type: "alert",
        message: msg
      };

      this.toasts.push(toast);
    }
  }
}
</script>