<template>
  <li class="nav-item dropdown">
    <a
      v-if="item.menu"
      class="nav-link dropdown-toggle"
      role="button" data-bs-toggle="dropdown" aria-expanded="false"
    >
      {{ item.title }}
    </a>
    <ul class="dropdown-menu">
      <li v-for="subitem in item.menu" :key="subitem.name">
        <router-link
          class="dropdown-item"
          :to="{ name: subitem.route }"
        >
          {{ subitem.title }}
        </router-link>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  props: {
    item: {
      type: Object,
    },
  },
  mounted() {},
  beforeDestroy() {
  },
  methods: {
  },
};
</script>