<template>
  <span>{{timeAgo}}</span>
</template>

<script>
import TimeAgo from 'javascript-time-ago'
import es from 'javascript-time-ago/locale/es'

TimeAgo.addDefaultLocale(es)
const timeAgo = new TimeAgo('es')

export default {
  props: {
    time: {
      required: true,
    }
  },
  data() {
    return {
      timeAgo: timeAgo.format(this.time * 1000)
    }
  }
}
</script>