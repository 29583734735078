<template>
  <design-two v-if="loaded" :title="forum.name">
    <template v-slot:breadcrumb>
      <bread-crumb v-bind:forum="forum"></bread-crumb>
    </template>
    <template v-slot:actions>
      <button type="button" class="btn btn-primary" v-on:click="newtopic(forum.id)">Nuevo tema</button>
    </template>
    <topic-list v-bind:topics="topics" v-if="topics"></topic-list>
    <div v-if="topics && topics.length === 0">
      Sin temas
    </div>
  </design-two>
</template>

<script>
import { DesignTwo } from "@linkedcode/design";
import TopicList from "../components/TopicList.vue"
import BreadCrumb from "../components/BreadCrumb.vue"

export default {
  computed: {
    authed() {
      return this.$store.state.auth.isAuthed;
    },
    forum() {
      return this.$store.state.forums.active;
    },
    topics() {
      const fid = this.$route.params.id;
      const topics = this.$store.state.topics.forum[fid];
      //console.log('computed:topics', topics);
      return topics;
    }
  },
  components: {
    DesignTwo,
    TopicList,
    BreadCrumb
  },
  data() {
    return {
      loaded: false
    }
  },
  created() {
  },
  methods: {
    newtopic(forumId) {
      this.$router.push({ name: 'newtopic', params: { id: forumId }});
    }
  },
  mounted() {
    var self = this;
    this.$store.dispatch("forums/load", { 
      forum_id: this.$route.params.id
    }).then(function(data){
      self.loaded = true;
    });
    this.$store.dispatch("topics/loadByForum", {
      forum_id: this.$route.params.id
    });
  }
}
</script>