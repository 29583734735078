<template>
  <div class="topic-reply-form mt-4">
    <h2>Responder</h2>
    <form>
      <fieldset>
        <textarea class="form-control" v-model="post.markdown"></textarea>
      </fieldset>
      <fieldset class="actions mt-4">
        <button v-on:click="reply" type="button" class="btn btn-primary">Responder</button>
      </fieldset>
    </form>
  </div>
</template>

<script>
export default {
  name: "TopicReply",
  id: "topic-reply",
  props: {
    topicid: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      post: {
        markdown: "**hola**"
      },
      topic_id: this.topicid
    }
  },
  created() {
    //console.log(this);
  },
  methods: {
    reply() {
      console.log(this.$data);
      this.$store.dispatch("posts/save", this.$data);
    }
  }
}
</script>