<template>
  <aside id="reset-modal" class="modal slideout">
    <h2>Resetear contraseña</h2>
    <form>
      <div class="alert alert-info" role="alert" v-if="errors.length">
        <div v-for="err in errors" v-bind:key="err.id">{{ err }}</div>
      </div>
      <fieldset>
        <legend>Correo electrónico</legend>
        <input
          type="text"
          v-model="email"
          class="form-control"
          autocomplete="username"
          placeholder="Usuario o correo electrónico"
        />
      </fieldset>
      <fieldset>
        <div class="form-group">
          <button type="button" class="first" v-on:click="cancel()">
            Cancelar
          </button>
          <button type="button" v-on:click="reset2()">
            Enviar enlace
          </button>
        </div>
      </fieldset>
    </form>
  </aside>
</template>

<script>
//import store from "@/store";

export default {
  name: "ResetModal",
  id: "reset-modal",
  data() {
    return {
      el: "reset",
      errors: [],
      email: "nhk.web@gmail.com"
    };
  },
  methods: {
    cancel() {
      this.reject("Cancelled");
    },
    reset2() {
      this.errors = [];
      var self = this;
      self.$store
        .dispatch("auth/reset", {
          email: self.email
        })
        .then(function(msg) {
          var obj = {
            props: {
              email: self.email
            }
          };
          self.$auth.newPassModal(obj).then(function(res){
            self.resolve(res);
          });
          //self.resolve(msg);
        }).catch(function(errors) {
          self.errors = errors;
        });;
    }
  }
};
</script>
