<template>
  <div class="container">
    <main>
      <div class="main-header">
        <h1>Perfil</h1>
      </div>
      <div class="row">
        <div class="col-8">
          <form autocomplete="false">
            <div class="mb-3">
              <label for="username" class="form-label">Nombre de perfil</label>
              <input type="email" class="form-control" v-model="item.name" id="username">
            </div>
            <div class="actions">
              <button type="button" @click="submit" class="btn btn-primary">Enviar</button>
            </div>
          </form>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'UserProfile',
  data() {
    return {
      item: {
        name: ''
      }
    }
  },
  methods: {
    submit() {
      this.$store.dispatch('profile/update', { item: this.item });
    }
  },
  mounted() {
    //console.log("mounted");
    var self = this;
    this.$store.dispatch('profile/load').then(function(item){
      //console.log(item);
      self.item.name = item.name;
    })
  }
}
</script>