<template>
  <div id="new-pass-modal" class="modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Nueva clave</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <fieldset>
              <label class="my-2">Codigo</label>
              <input
                type="text"
                v-model="code"
                class="form-control"
                placeholder="Codigo enviado x correo"
              />
              <label class="my-2">Clave</label>
              <input
                type="password"
                minlength="8"
                v-model="password"
                class="form-control"
              />
              <label class="my-2">Repetir clave</label>
              <input
                type="password"
                minlength="8"
                v-model="password2"
                class="form-control"
              />
            </fieldset>
          </form>
          </div>
        <div class="modal-footer">
          
          <button
            type="button"
            class="btn btn-secondary"
            
            v-on:click="cancel()"
          >
            Cancelar
          </button>
          <button type="button" v-on:click="save()" class="btn btn-primary">Guardar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import store from "@/store";

export default {
  name: "NewPassModal",
  id: "new-pass-modal",
  data() {
    return {
      el: "newpass",
      errors: [],
      code: "",
      password: "",
      password2: "",
    };
  },
  props: {
    email: {
      type: String,
    },
  },
  methods: {
    cancel() {
      this.reject("Cancelled new pass");
    },
    save() {
      if (this.password != this.password2) {
        //console.log("los pass no coinciden");
        return;
      }
      if (this.password == "" || this.password2 == "") {
        //console.log("los pass estan vacios");
        return;
      }
      this.errors = [];
      var self = this;
      self.$store
        .dispatch("auth/resetsave", {
          email: this.email,
          code: this.code,
          password: this.password,
        })
        .then(function (msg) {
          //console.log("Login OK");
          self.resolve(msg);
        })
        .catch(function (errors) {
          self.errors = errors;
        });
    },
  },
};
</script>
