<template>
  <div class="modal-content-wrapper">
      <component v-for="modal in modals" :key="modal.component.id"
        v-bind:is="modal.component.id"
        v-bind="modal.props"
      ></component>
  </div>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      props: {},
      modals: []
    };
  },
  created() {
    var vm = this;
    //console.log(vm.$emitter);

    vm.$emitter.on("OPEN_MODAL", function(data) {
      data.component.methods.resolve = function(payload) {
        vm.closeModal(data.component.name, data.component.id);
        data.resolve(payload);
      };

      data.component.methods.reject = function(payload) {
        vm.closeModal(data.component.name, data.component.id);
        data.reject(payload);
      };

      if (vm.modals.length > 0) {
        vm.modals.forEach(function(modal, idx) {
          console.log('ocultando', modal.component.id);
          var el = document.getElementById(modal.component.id);
          var modal = bootstrap.Modal.getInstance(el);
          modal.hide();
        });
      }
      
      vm.modals.push(data);

      vm.$nextTick(function() {
        console.log('nexttick', data.component.id);
        var el = document.getElementById(data.component.id);

        var modal = new bootstrap.Modal(el, {
          keyboard: false
        });

        modal.show();
      })
    });
  },
  methods: {
    closeModal(name, id) {
      console.log('closeModal', name, id);
      var el = document.getElementById(id);

      //var modal = new bootstrap.Modal(el, { keyboard: false });
      var modal = bootstrap.Modal.getInstance(el);

      modal.hide();

      this.modals.forEach(function(modal, idx) {
        if (modal.component.name === name) {
          this.modals.splice(idx, 1);
        }
      }, this);
      
      if (this.modals.length === 1) {
        this.modals.forEach(function(modal, idx) {
          console.log('remostrando', modal.component.id);
          var el = document.getElementById(modal.component.id);
          var modal = bootstrap.Modal.getInstance(el);
          modal.show();
        });
      }
    },
  },
};
</script>
