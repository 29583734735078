import { useToast } from 'vue-toastification'
import "vue-toastification/dist/index.css";

const toast = useToast()

export default {
  namespaced: true,
  state: {
    notifications: []
  },
  mutations: {
    error(state, data) {
      state.notifications.push(data);
    },
    success(state, data) {
      state.notifications.push(data);
    }
  },
  actions: {
    error({ commit }, data) {
      toast.error(data.msg)
    },
    success({ commit }, data) {
      toast.success(data.msg);
    }
  }
};
