<template>
  <design-two v-if="topic.title" :title="topic.title">
    <template v-slot:breadcrumb>
      <bread-crumb v-bind:forum="topic.forum"></bread-crumb>
    </template>

    <div class="row bg-light p-2 mb-4">
      <div class="col-8" v-html="topic.content"></div>
      <div class="col-4" v-if="topic.user">
        <div class="row mb-2">
          <div class="col-12">
            <span class="topic-info-author">{{topic.user.name}}</span>
          </div>
          <div class="col-12">
            <time-ago class="topic-info-date" v-bind:time="topic.created_at"></time-ago>
          </div>
          <div class="col-12">
            <span class="topic-info-actions">
              <button class="btn btn-primary" v-if="isOwner" v-on:click="edit()">Editar</button>
            </span>
          </div>
        </div>
      </div>
    </div>

    <replies v-if="posts" :replies="posts"></replies>

    <div v-if="noReplies">
      No hay respuestas
    </div>

    <topic-reply v-if="isAuthed" :topicid="topicId"></topic-reply>
  </design-two>
</template>

<script>
import { DesignTwo } from "@linkedcode/design";
import TopicReply from "../components/TopicReply.vue";
import Replies from "../components/Replies.vue";
import TimeAgo from "../components/TimeAgo.vue"
import BreadCrumb from '../components/BreadCrumb.vue';

export default {
  computed: {
    topic() {
      return this.$store.state.topics.active
    }, 
    posts() {
      return this.$store.state.posts.all
    },
    noReplies() {
      return (this.$store.state.posts.all.length === 0)
    },
    isAuthed() {
      return this.$store.getters['auth/isAuthed'];
    },
    isOwner() {
      let uid = this.$store.getters['auth/userId'];
      //console.log(uid, this.topic.user.id);
      return (uid == this.topic.user.id);
    },
    topicId() {
      return parseInt(this.$route.params.id);
    }
  },
  components: {
    DesignTwo,
    TopicReply,
    Replies,
    TimeAgo,
    BreadCrumb
  },
  data() {
    return {
      topic_id: 0,
    }
  },
  methods: {
    edit() {
      this.$router.push({ name: 'topic-edit', params: { id: this.topic.id }});
    },
    _reply() {
      if (!this.$auth.isAuth()) {
        this.$auth.loginModal();
      } else {
        //this.$auth.loginModal();
        //this.$modal(TopicReply);
        this.$openModal({
          component: TopicReply
        });
      }
    }
  },
  created() {
    //console.log(this.$auth);
    //this.topic_id = this.topicId;
    //console.log(this.topicId);
    this.$store.dispatch("topics/load", { 
      topic_id: this.$route.params.id
    });
  },
  mounted() {
    this.$store.dispatch("posts/byTopic", { 
      topic_id: this.$route.params.id
    });
  }
}
</script>
