import Navbar from "./Navbar.vue";
import SidebarMenu from "./SidebarMenu.vue";
import NavbarHeader from "./NavbarHeader.vue";

export default {
  installed: false,
  install(app, options) {
    if (this.installed) return;
    app.component("navbar-menu", Navbar);
    app.component("sidebar-menu", SidebarMenu);
    app.component("navbar-header", NavbarHeader);
    this.installed = true;
  }
};
