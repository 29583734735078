<template>
  <div id="register-modal" class="modal slideout" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Registro</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form novalidate="true" v-bind:class="formCss">
            <fieldset>
              <label class="mb-2" for="email">Correo electrónico</label>
              <input
                type="email"
                v-model="email"
                class="form-control"
                id="email"
                minlength="6"
                autocomplete="off"
                placeholder="Correo electrónico"
                required
              />
              <div v-if="errors.email" class="invalid-feedback">
                <ul class="list-unstyled">
                  <li v-for="(err, idx) in errors.email" v-bind:key="idx">{{err.message}}</li>
                </ul>
              </div>

              <label class="my-2" for="password">Contraseña</label>
              <input
                type="password"
                v-model="password"
                autocomplete="new-password"
                class="form-control"
                placeholder="Contraseña"
                minlength="8"
                required
              />
              <div v-if="errors.password" class="invalid-feedback">
                <ul class="list-unstyled">
                  <li v-for="(err, idx) in errors.password" v-bind:key="idx">{{err.message}}</li>
                </ul>
              </div>
            </fieldset>
          </form>
        </div>
        <div class="modal-footer">
          <!-- data-bs-dismiss="modal" -->
          <button type="button" class="btn btn-secondary" v-on:click="cancel()">
            Cancelar
          </button>
          <button type="button" v-on:click="register()" class="btn btn-primary">
            Registro
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import store from "@/store";

export default {
  name: "RegisterModal",
  id: "register-modal",
  data: function () {
    return {
      errors: [],
      email: "",
      password: "",
      formCss: {
        "was-validated": false
      }
    };
  },
  methods: {
    cancel() {
      this.reject("Cancelled");
    },
    register() {
      this.errors = [];
      this.formCss['was-validated'] = true;
      let self = this;

      var data = {
        email: this.email,
        password: this.password,
      };

      this.$store
        .dispatch("auth/register", data)
        .then(function (msg) {
          self.resolve(data);
        })
        .catch(function (errors) {
          self.errors = errors;
        });
    },
  },
};
</script>
