<template>
  <div class="collapse navbar-collapse">
    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      <li class="nav-item" v-for="item in menu" :key="item.name">
        <router-link class="nav-link"  v-if="!item.menu" :to="{ name: item.route.name }">
          {{ item.title }}
        </router-link>
        <menu-dropdown-bootstrap v-if="item.menu" v-bind:item="item"></menu-dropdown-bootstrap>
      </li>
    </ul>
  </div>
</template>
<!--template>
  <nav class="nav">
    <ul>
      <li v-for="item in menu" :key="item.name">
        <router-link  v-if="!item.menu" :to="{ name: item.route.name }">
          {{ item.title }}
        </router-link>
        <menu-dropdown v-if="item.menu" v-bind:item="item"></menu-dropdown>
      </li>
    </ul>
  </nav>
</template-->

<script>
import MenuDropdownBootstrap from './MenuDropdownBootstrap.vue';

export default {
  id: "navbar-menu",
  name: "NavbarMenu",
  data: function() {
    return {
      selected: null
    }
  },
  components: {
    MenuDropdownBootstrap
  },
  methods: {
    home() {
      
    },
    navigateTo() {
      
      //this.$store.dispatch("config/closeSliderMenu");
    },

    select(id) {
      if (this.selected === id) {
        this.selected = null;
      } else {
        this.selected = id;
      }
    }
  },
  computed: {
    menu: function() {
      if (this.$store.state.apps.active === null) {
        //console.log("HeaderMenu SIN app activa");
        return [];
      } else {
        let activeApp = this.$store.state.apps.active;
        let menu = activeApp.menu;
        //console.log("HeaderMenu CON app activa", menu, activeApp);
        return menu;
      }
    },
    brand: function() {
      return 'Marca';
    }
  }
};
</script>
