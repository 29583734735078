<template>
  <design-two title="Nuevo tema">
    <template v-slot:breadcrumb>
      <bread-crumb :forum="forum"></bread-crumb>
    </template>
    <form>
      <fieldset>
        <label class="my-2">Titulo</label>
        <input class="form-control" type="text" v-model="topic.title" />
        <label class="my-2">Mensaje</label>
        <textarea class="form-control" v-model="topic.markdown"></textarea>
      </fieldset>
    </form>
    <div class="d-flex justify-content-between my-4">
        <button type="button" class="btn btn-secondary" v-on:click="cancel">Cancelar</button>
        <button type="button" class="btn btn-primary" v-on:click="submit()">Guardar</button>
    </div>
  </design-two>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb.vue';
import { DesignTwo } from "@linkedcode/design";

export default {
  name: "NewTopic",
  computed: {
    forum() {
      return this.$store.state.forums.active;
    }
  },
  components: {
    BreadCrumb,
    DesignTwo
  },
  data() {
    return {
      topic: {
        title: '',
        markdown: '',
      },
      forumId: 0,
      topicId: 0
    }
  },
  methods: {
    submit() {
      if (this.topicId > 0) {
        this.update();
      } else {
        this.save();
      }
    },
    save() {
      let self = this;
      this.$store.dispatch("topics/create", {
        topic: this.topic,
        forumId: this.forumId
      }).then(function() {
        self.$router.push({ name: 'forum', params: {
            id: self.$route.params.id
        }});
      });
    },
    update() {
      let self = this;
      let data = {
        topic: this.topic,
        topic_id: this.topicId
      };
      console.log(data);
      this.$store.dispatch("topics/update", data).then(function() {
        self.$router.push({ name: 'topic', params: {
            id: self.topicId
        }});
      });
    },
    cancel() {
      this.$router.go(-1);
    }
  },
  created() {
    //console.log(this.$route);
    var vm = this;
    if (this.$route.name == 'topic-edit') {
      this.topicId = this.$route.params.id;
      this.$store.dispatch("topics/edit", { topic_id: this.topicId })
        .then(function(topic){
          vm.topic.title = topic.title;
          vm.topic.markdown = topic.markdown;
        }
      )
    }
  },
  mounted() {
    this.forumId = this.$route.params.id;
    this.$store.dispatch('forums/load', { forum_id: this.forumId });
  }
}
</script>
