import MenuDropdown from "./MenuDropdown.vue"

export default {
  id: "navbar-menu",
  name: "NavbarMenu",
  data: function() {
    return {
      selected: null
    }
  },
  components: {
    MenuDropdown
  },
  methods: {
    home() {
      console.log("home");
    },
    navigateTo() {
      console.log('navTo');
      //this.$store.dispatch("config/closeSliderMenu");
    },

    select(id) {
      if (this.selected === id) {
        this.selected = null;
      } else {
        this.selected = id;
      }
    }
  },
  computed: {
    menu: function() {
      if (this.$store.state.apps.active === null) {
        //console.log("HeaderMenu SIN app activa");
        return [];
      } else {
        let activeApp = this.$store.state.apps.active;
        let menu = activeApp.menu;
        //console.log("HeaderMenu CON app activa", menu, activeApp);
        return menu;
      }
    },
    brand: function() {
      return 'Marca';
    }
  }
};
