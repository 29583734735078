<template>
    <div class="row post bg-light mb-4 p-2" :id="'post-' + post.id">
      <div class="col-8 post-content" v-html="post.content"></div>
      <div class="col-4 post-info">
        <ul class="list-unstyled">
          <li><span class="post-info-id">#{{post.id}}</span></li>
          <li><span class="post-info-author">{{post.user.name}}</span></li>
          <li><time-ago class="post-info-date" v-bind:time="post.created_at"></time-ago></li>
          <li>
            <span class="post-info-actions">
              <button type="button" v-if="isOwner" class="btn btn-secondary" v-on:click="edit()">Editar</button>
            </span>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
import TimeAgo from "./TimeAgo.vue"

export default {
  props: {
    post: {
      type: Object
    }
  },
  components: {
    TimeAgo
  },
  methods: {
    edit() {
      this.$router.push({
        name: 'post-edit', params: { id: this.post.id }
      })
    }
  },
  computed: {
    isOwner() {
      let uid = this.$store.getters['auth/userId'];
      //console.log(uid, this.topic.user.id);
      return (uid == this.post.user.id);
    },
  }
}
</script>