<template>
  <li class="nav-item dropdown">
    <a
      v-if="item.menu"
      class="nav-link dropdown-toggle"
      v-on:click="toggle"
      role="button"
    >
      {{ item.title }}
    </a>
    <ul class="dropdown-menu" v-if="this.show">
      <li v-for="subitem in item.menu" :key="subitem.name">
        <router-link
          class="dropdown-item"
          :to="{ name: subitem.route }"
          v-on:click="close"
        >
          {{ subitem.title }}
        </router-link>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  props: {
    item: {
      type: Object,
    },
  },
  mounted() {},
  beforeDestroy() {
    this.remove();
  },
  methods: {
    toggle() {
      this.show = !this.show;
      if (this.show) {
        document.addEventListener("click", this.clickHandler);
      } else {
        this.remove();
      }
    },
    clickHandler(event) {
      ///console.log("clickHandler");
      const { target } = event;
      const { $el } = this;
      if (!$el.contains(target)) {
        this.remove();
      }
    },
    close() {
      this.remove();
    },
    remove() {
      //console.log("removing");
      this.show = false;
      document.removeEventListener("click", this.clickHandler);
    },
  },
};
</script>