<template>
  <div id="register-verify-modal" class="modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Verificacion de Registro</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="alert alert-info" role="alert" v-if="errors.length">
              <div v-for="err in errors" v-bind:key="err.id">{{ err }}</div>
            </div>
            <fieldset>
              <label class="py-2" for="email">Codigo</label>
              <input
                type="text"
                v-model="code"
                class="form-control"
                placeholder="Codigo enviado al email"
              />
            </fieldset>
          </form>
        </div>
        <div class="modal-footer">
          <div class="hstack gap-2">
            <button type="button" class="btn btn-secondary" v-on:click="newcode()">Solicitar nuevo codigo</button>
            <!-- data-bs-dismiss="modal" -->
            <button
              type="button"
              class="btn btn-secondary ms-auto"
              
              v-on:click="cancel()"
            >
              Cancelar
            </button>
            <button type="button" v-on:click="verify()" class="btn btn-primary">Verificar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import store from "@/store";

export default {
  name: "RegisterVerifyModal",
  id: "register-verify-modal",
  data: function() {
    return {
      errors: [],
      code: ""
    };
  },
  props: {
    password: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },
  methods: {
    cancel() {
      this.reject("Cancelled");
    },
    newcode() {
      let self = this;

      this.$store
        .dispatch("auth/verifycode", {
          email: self.email,
          password: self.password
        })
        .then(function(msg) {
          //self.resolve(msg);
          //console.log(msg);
        })
        .catch(function(errors) {
          self.errors = errors;
        });
    },
    verify() {
      this.errors = [];
      let self = this;

      this.$store
        .dispatch("auth/verify", {
          email: self.email,
          password: self.password,
          code: self.code
        })
        .then(function(msg) {
          self.resolve(msg);
        })
        .catch(function(errors) {
          self.errors = errors;
        });
    }
  }
};
</script>
