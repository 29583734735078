<template>
  <div class="main-wrapper mt-2">
    <hero-title :title="title">
      <template v-slot:breadcrumb>
        <slot name="breadcrumb"></slot>
      </template>
      <template v-slot:actions>
        <slot name="actions"></slot>
      </template>
    </hero-title>
    <main>
      <main-header-wrapper v-if="hasMainHeader">
        <slot name="mainHeader"></slot>
      </main-header-wrapper>
      <main-content-wrapper>
        <slot name="default"></slot>
      </main-content-wrapper>
    </main>
    <aside>
      <slot name="aside"></slot>
    </aside>
  </div>
</template>

<script>
import HeroTitle from './HeroTitle.vue'
import MainContentWrapper from './MainContentWrapper.vue'
import MainHeaderWrapper from './MainHeaderWrapper.vue'

export default {
  components: { MainHeaderWrapper, MainContentWrapper, HeroTitle },
  name: "DesignTwo",
  props: {
    title: {
      required: true,
      type: String,
    }
  },
  computed: {
    hasMainHeader() {
      return (typeof this.$slots.mainHeader !== 'undefined');
    }
  }
}
</script>