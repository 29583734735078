<template>
  <div class="topic-list">
    <div class="row my-2">
      <div class="col-8">Titulo</div>
      <div class="col-2">Respuestas</div>
      <div class="col-2">Actividad</div>
    </div>
    <div class="row my-2 bg-light py-2" v-for="topic in topics" v-bind:key="topic.id">
      <div class="col-8 topic-title">
        <router-link :to="{ name: 'topic', params: { id: topic.id }}">
          {{topic.title}}
        </router-link>
        <span v-if="showForum">
          en         
            <router-link :to="{ name: 'forum', params: { id: topic.forum.id }}">
              {{topic.forum.name}}
            </router-link>
        </span>
      </div>
      <div class="col-2 replies">
        {{ topic.post_count }}
      </div>
      <div class="col-2 last-activity">
        <time-ago v-bind:time="topic.last_activity_at"></time-ago>
      </div>
    </div>
  </div>
</template>

<script>
import TimeAgo from "./TimeAgo.vue"

export default {
  name: "TopicList",
  components: {
    TimeAgo
  },
  props: {
    showForum: {
      type: Boolean,
      default: false,
      required: false
    },
    topics: {
      type: Array,
      required: true
    }
  }
}
</script>