<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <router-link class="navbar-brand" to="/">{{brand}}</router-link>
      <navbar-menu />
      <div id="system-area">
        <user-applet></user-applet>
      </div>
    </div>
  </nav>
</template>

<script>
import NavbarMenu from "./Navbar.vue"

export default {
  name: "NavbarHeader",
  components: {
    NavbarMenu
  },
  props: {
    brand: {
      type: String,
      required: true
    }
  }
}
</script>