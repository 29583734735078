<template>
  <div class="main-content-wrapper">
    <div class="main-content container">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainContentWrapper"
}
</script>